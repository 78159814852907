import React from 'react'
import { graphql } from 'gatsby'
import { SEO, Layout, MainImageBlock, DescriptionBlock, Divider } from 'Components'

import './about.css'

export const HomeQuery = graphql`
  query {
    about: allContentfulAbout {
      totalCount
      edges {
        node {
          title
          subtitle
          mainImage {
            title
            description
            fluid(maxWidth: 1200, quality: 85) {
              ...GatsbyContentfulFluid
              sizes
              srcSet
              sizes
              src
              srcSetWebp
            }
            fixed(width: 1200, height: 630) {
              width
              height
              src
            }
          }
          description {
            description
          }
        }
      }
    }
  }
`

const About = ({ data }) => {
  const { about } = data
  const {
    title,
    subtitle,
    description: { description },
    mainImage,
  } = about.edges[0].node

  return (
    <>
      <SEO pathname="about" title="About" description={description} image={mainImage.fixed.src} />
      <Layout>
        <section className="About self-center p-4 xl:p-12">
          <div className="text-center mx-auto w-3/4 my-10 lg:my-20 lg:w-2/4 ProjectTitleBlock">
            <div className="text-xl dark:text-gray-600 text-gray-400 subpixel-antialiased">{subtitle}</div>
            <h1 className="text-3xl subpixel-antialiased dark:text-gray-300 text-gray-600">{title}</h1>
          </div>
          <MainImageBlock title={mainImage.title} description={mainImage.description} fluid={mainImage.fluid} />
          <DescriptionBlock description={description} />
          <Divider />
          <div className="text-center mx-auto w-3/4 my-10 lg:my-20 lg:w-2/4 ProjectTitleBlock">
            <div className="text-xl dark:text-gray-600 text-gray-400 subpixel-antialiased">PRACTICE</div>
            <h1 className="text-3xl subpixel-antialiased dark:text-gray-300 text-gray-600">Office</h1>
          </div>
          <DescriptionBlock
            description='In our age of time, the practice of the architect profession may introduce additional layers. An architectural project, in our mind, shall be inclusive of various visual expressions that incorporate the main notions of place and time.
Our team composed of architects and Landscape architects finds it a great opportunity and challenge to contribute to the creation of an adaptable ways of living and working nowadays. This interdisciplinary approach may involve opening a new type of structures to its immediate environment, a composite activities and multifunctional spaces, which can be adapted to a large variety of scales
We can observe that today’s humanity has reached a stage of total displacement, facing multiple scales and frames, which present challenges to contemporary constructions.
Our practice is pursuing to integrate space into those positions in a context of dissemination of resources and their migrations, of no visible frontiers, while maintaining a dynamic understanding of the human need to have a comfortable constructive environment. In this search of inhabitant, people move on the surface of the planet and by this simple "travelling" ,by doing that ,they create transformations/formations/mutations, which involve juxtapositions in their own way to live.
To give a proper answers to those needs, this invites us to think beyond the traditional frames of architecture, landscape, and building models. This supposes us to be open to new perceptions of knowhow and technologies that rely on life models, that is to say on exchanges between materials and their elements. The architecture of a contemporary time is the opportunity to design inclusive structures that open to these challenges. As such it may exceed issues of motion in space and is part of a universal, trans-generation and trans-disciplinary dimensions. In other words, the way of building.'
          />

          <Divider />

          <div className="text-center mx-auto w-3/4 my-10 lg:my-20 lg:w-2/4 ProjectTitleBlock">
            <h1 className="text-3xl subpixel-antialiased dark:text-gray-300 text-gray-600 mb-6">Get in Touch</h1>
            <div className="text-center w-3/4 lg:w-2/4 my-0 mx-auto max-w-md">
              <ul className="flex flex-wrap justify-around">
                <li className="flex flex-col text-center mb-6">
                  <span className="text-black dark:text-white">Ben Gitai</span>
                  <a href="mailto:ben@gitaiarchitects.com" className="underline dark:text-gray-500 text-gray-700">
                    ben@gitaiarchitects.com
                  </a>
                </li>
                <li className="flex flex-col text-center mb-6">
                  <span className="text-black dark:text-white">Information</span>
                  <a href="mailto:info@gitaiarchitects.com" className="underline dark:text-gray-500 text-gray-700">
                    info@gitaiarchitects.com
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <Divider />

          <div className="text-center mx-auto w-3/4 my-10 lg:my-20 lg:w-2/4 ProjectTitleBlock">
            <h1 className="text-3xl subpixel-antialiased dark:text-gray-300 text-gray-600 mb-6">Address</h1>
            <div className="text-center w-3/4 lg:w-2/4 my-0 mx-auto max-w-md">
              <ul className="flex flex-wrap justify-around">
                <li className="flex flex-col text-center mb-6">
                  <span className="dark:text-gray-300 text-gray-600">Office Israel - 135 Hanasi Bulv, 3463417</span>
                  <span className="font-bold text-black dark:text-white">Haifa, Israel</span>
                  <div>
                    <span className="mr-2 dark:text-gray-300 text-gray-600">Phone:</span>
                    <a href="tel:+97248712311" className="underline dark:text-gray-500 text-gray-700 mr-4">
                      +97248712311
                    </a>
                    <span className="mr-2 dark:text-gray-300 text-gray-600">Fax:</span>
                    <a href="tel:+97248345677" className="underline dark:text-gray-500 text-gray-700">
                      +97248345677
                    </a>
                  </div>
                </li>
                <li className="flex flex-col text-center mb-6">
                  <span className="dark:text-gray-300 text-gray-600">Office Paris - 6 Cours Berard, 75004</span>
                  <span className="font-bold text-black dark:text-white">Paris, France</span>
                  <div>
                    <span className="mr-2 dark:text-gray-300 text-gray-600">Phone:</span>
                    <a href="tel:+33142404845" className="underline dark:text-gray-500 text-gray-700 mr-4">
                      +33142404845
                    </a>
                    <span className="mr-2 dark:text-gray-300 text-gray-600">Fax:</span>
                    <a href="tel:+33148413917" className="underline dark:text-gray-500 text-gray-700">
                      +33148413917
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default About
